<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Solicitud de material
            </div>
            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form :disabled="loading">
                            <v-row>
                                <v-col cols="12" sm="5" md="3" lg="3" xl="2">
                                    <SolStaFind class="mb-n7" ref="solStaCtrl" v-model="filtros.SolSta" label="Estado"></SolStaFind>
                                </v-col>
                                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                                    <PerFind class="mb-n7" ref="perCtrl" v-model="filtros.Per" :perCatIdAnyLevel="perCatIdEmpleados" :isRrhh="$store.state.esRRHH" label="Empleado"></PerFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <PerRespFind class="mb-n7" ref="respCtrl" v-model="filtros.Resp" :perCatIdAnyLevel="perCatIdEmpleados" label="Responsable"></PerRespFind>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" xl="3">
                                    <PerCatFind class="mb-n7" ref="perCatCtrl" v-model="filtros.PerCat" label="Departamento"></PerCatFind>
                                </v-col>
                                <v-col cols="12" sm="9" md="8" lg="6" xl="4">
                                    <DirFind class="mb-n7" ref="dirCtrl" v-model="filtros.Dir" label="Dirección de entrega"></DirFind>
                                </v-col>
                                <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Desde" v-model="filtros.FechaDesde"></DateFind>
                                </v-col>
                                <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                                    <DateFind label="Hasta" v-model="filtros.FechaHasta"></DateFind>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-btn v-if="$store.state.esRRHH"
                to="/solicitud-material-nuevo"
                color="primary"
                class="mt-2">
                Nueva solicitud
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarEstado"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 mt-2"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolFec="{ item }">
                    {{ formatDate(item.SolFec) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolStaFec="{ item }">
                    {{ formatDate(item.SolStaFec) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.SolStaNom="{item}">
                    <div v-if="item.edit" style="width: 260px;">
                        <SolStaFind
                            class="mt-2 mb-n5 mr-2" 
                            ref="solStaCtrl"
                            label="Estado"
                            v-model="item.newSolSta"
                            :minSolSta="item.SolStaId"
                            :maxSolSta="item.SolStaId < 109 ? 59 : null"
                            :readonly="item.loading"
                            @change="onChangeSelectEstado(item)">
                        </SolStaFind>
                        <!-- <v-btn
                            @click.stop="onBtnGuardarEstado(item)"
                            color="primary"
                            class="mr-2 my-1"
                            small
                            :loading="item.loading"
                            :disabled="item.SolStaId == item.newSolSta.SolStaId"
                            style="width:30px; min-width:30px;">
                            <v-icon>mdi-check-bold</v-icon>
                        </v-btn>
                        <v-btn
                            @click.stop="onBtnCancelarEdicion(item)"
                            class="my-1"
                            small
                            style="width:30px; min-width:30px;">
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn> -->
                    </div>
                    <v-chip v-else
                        :color="statusColor(item.SolStaId)"
                        small
                        style="min-width:85px"
                        class="status-chip">
                        {{item.SolStaNom}}
                    </v-chip>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Opt="{ item }">
                    <div :style="($store.state.esRRHH && (item.SolStaId === statusRefs.Aceptado || item.SolStaId === statusRefs.Devuelto)) ? {minWidth: '121px'} : {}">
                    <v-btn
                        :to="'/solicitud-material-responsable-detalle/' + item.SolId"
                        class="mr-2 my-1"
                        small
                        @click.stop
                        style="width:30px; min-width:30px;">
                        ...
                    </v-btn>
                    <v-btn v-if="$store.state.esRRHH && (item.SolStaId === statusRefs.Aceptado || item.SolStaId === statusRefs.Devuelto)"
                        :to="'/solicitud-material-editar/' + item.SolId"
                        class="mr-2 my-1" color="primary"
                        small
                        @click.stop
                        style="min-width:75px;">
                        Editar
                    </v-btn>
                    </div>
                </template>
            </v-data-table>
            <v-row class="mt-3 mb-4" justify="center">
              <v-btn
                  :loading="loading || downloading"
                  class="mr-2"
                  @click="onBtnDownloadReport('PDF')">
                  Exportar
                  <v-icon color="red" right size="25">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn
                  :loading="loading || downloading"
                  @click="onBtnDownloadReport('EXCELOPENXML')">
                  Exportar
                  <v-icon color="green" right size="25">mdi-microsoft-excel</v-icon>
              </v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import DateFind from "../components/DateFind.vue";
import DirFind from "../components/DirFind.vue";
import PerFind from "../components/PerFind.vue";
import PerCatFind from "../components/PerCatFind.vue";
import PerRespFind from "../components/PerRespFind.vue";
import SolStaFind from "../components/SolStaFind.vue";
import { mapState } from "vuex";
export default ({
    components: {
        DateFind, DirFind, PerFind, PerCatFind, PerRespFind, SolStaFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            downloading: false,
            statusRefs: {
              Pendiente: 10,
              Aceptado: 20,
              Rechazado: 30,
              EnTramite: 40,
              Enviado: 50,
              RecibidoConforme: 60,
              RecibidoNoConforme: 70,
              Devuelto: 110,
              DevueltoConforme: 120,
              DevueltoNoConforme: 130
            },
            perCatIdEmpleados: 101,
            activePanels: 0,
            filtros: {
                SolSta: null,
                Per: null,
                Resp: null,
                Dir: {},
                PerCat: {},
                FechaDesde: null,
                FechaHasta: null
            },
            selectedItem: null,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Persona', width: 175, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'PerNom' },
                { text: 'Material', width: 170, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'MatNom' },
                { text: '', width: 0, class: 'px-1', cellClass: 'px-1', sortable: false, value: 'MatVarNom1' },
                { text: '', width: 0, class: 'px-1', cellClass: 'px-1', sortable: false, value: 'MatVarNom2' },
                { text: 'Cantidad', width: 50, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'SolCant', align: 'end' },
                { text: 'Fecha solicitud', width: 130, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'SolFec' },
                { text: 'Estado', width: 100, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'SolStaNom' },
                { text: 'Fecha estado', width: 130, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'SolStaFec' },
                { text: 'Dirección de entrega', width: 150, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'DirNom' },
                { text: '', width: 40, class: 'px-2', cellClass: 'px-2', sortable: false, value: 'Opt', align: 'end' },
            ],
            dtOptionsLoaded: false
        }
    },
    watch: {
      options: {
        handler () {
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      filtros: {
        handler () {
          this.loadData();
        },
        deep: true
      },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.solStaCtrl.$refs.SolStaFindCtrl.isMenuActive = false;
            this.$refs.perCtrl.$refs.PerFindCtrl.isMenuActive = false;
            this.$refs.respCtrl.$refs.PerRespFindCtrl.isMenuActive = false;
            this.$refs.perCatCtrl.$refs.PerCatFindCtrl.isMenuActive = false;
            this.$refs.dirCtrl.$refs.DirFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      this.filtros.Resp = { PerId: this.perId, PerNom: this.$store.state.nombreUsuario };
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            EmpId: this.empId,
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            Filtros: {
                SolStaId: this.filtros.SolSta == null ? null : this.filtros.SolSta.SolStaId,
                PerId: this.filtros.Per == null ? null : this.filtros.Per.PerId,
                RespId: this.filtros.Resp == null ? null : this.filtros.Resp.PerId,
                DirId: this.filtros.Dir == null ? null : this.filtros.Dir.DirId,
                PerCatId: this.filtros.PerCat == null ? null : this.filtros.PerCat.PerCatId,
                FechaDesde: this.filtros.FechaDesde,
                FechaHasta: this.filtros.FechaHasta
            }
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  items,
                  total
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar las solicitudes.');
            }
          });
        })
      },
      downloadReportFromApi(fileType) {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_SOL_REPORT',
            EmpId: this.empId,
            FileType: fileType,
            Filtros: {
                SolStaId: this.filtros.SolSta == null ? null : this.filtros.SolSta.SolStaId,
                PerId: this.filtros.Per == null ? null : this.filtros.Per.PerId,
                RespId: this.filtros.Resp == null ? null : this.filtros.Resp.PerId,
                DirId: this.filtros.Dir == null ? null : this.filtros.Dir.DirId,
                PerCatId: this.filtros.PerCat == null ? null : this.filtros.PerCat.PerCatId,
                FechaDesde: this.filtros.FechaDesde,
                FechaHasta: this.filtros.FechaHasta
            }
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al exportar las solicitudes.');
            }
          });
        })
      },
      postSolStaToApi(item) {
        item.loading = true;
        this.$forceUpdate();

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'CHANGE_SOL_STA',
            SolId: item.SolId,
            SolStaId: item.newSolSta.SolStaId,
            PerId: this.perId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const success = result.data;

            setTimeout(() => {
              item.loading = false;
              resolve({
                success
              })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cambiar el estado.');
            }
          });
        })
      },
      loadData() {
        this.selectedItem = null;
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      onClickRowEditarEstado(item) {
        if(item.loading) { return; }
        if(item.edit) { return; }
        
        // Si esta marcado como recibido no se puede cambiar el estado
        if(item.SolStaId >= this.statusRefs.RecibidoConforme && item.SolStaId != this.statusRefs.Devuelto) { return; }
        
        // Si se está editando algún elemento lo desmarcamos
        if(this.selectedItem != null) {
          const actualIndex = this.dataRows.map(m => m.SolId).indexOf(this.selectedItem);
          if(actualIndex != -1) {
            this.dataRows[actualIndex].edit = false;
            this.dataRows[actualIndex].newSolSta = {};
          }
        }
        
        // Marcamos el elemento elegido para la edición
        this.selectedItem = item.SolId;

        item.edit = true;
        item.loading = false;
        item.newSolSta = {
          SolStaId: item.SolStaId,
          SolStaNom: item.SolStaNom
        };
        this.$forceUpdate();
      },
      onChangeSelectEstado(item) {
        if(item.newSolSta == null) { return; }
        if(item.SolStaId == item.newSolSta.SolStaId) { return; }
        this.onBtnGuardarEstado(item);
        // this.$forceUpdate();
      },
      onBtnGuardarEstado(item) {
        this.postSolStaToApi(item).then(data => {
          if(data.success == true) {
              item.SolStaId = item.newSolSta.SolStaId;
              item.SolStaNom = item.newSolSta.SolStaNom;
              item.SolStaFec = new Date();
              item.edit = false;
              this.$forceUpdate();
              item.newSolSta = {};
          }
        });
      },
      onBtnCancelarEdicion(item) {
          item.edit = false;
          this.$forceUpdate();  
      },
      onBtnDownloadReport(fileType) {
        this.downloadReportFromApi(fileType)
        .then(data => {
            if(data.item === '') { 
              alert('Documento no encontrado.');
              return; 
            }
            this.descargarDocumento(data.item.File, data.item.FileName);
        });
      },
      descargarDocumento(doc, docName) {
        this.downloading = true;

        // Datos del fichero
        const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        // Descarga del fichero
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a'); 
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = docName; // Nombre del fichero
        a.click();
        a.remove();

        this.downloading = false;
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      statusColor(status) {
        if(status === this.statusRefs.Aceptado) { return 'green'; }
        if(status === this.statusRefs.Rechazado) { return 'red'; }
        if(status === this.statusRefs.EnTramite) { return 'green'; }
        if(status === this.statusRefs.Enviado) { return 'green'; }
        if(status === this.statusRefs.RecibidoConforme) { return 'green'; }
        if(status === this.statusRefs.RecibidoNoConforme) { return 'yellow'; }
        if(status === this.statusRefs.DevueltoConforme) { return 'green'; }
        if(status === this.statusRefs.DevueltoNoConforme) { return 'red'; }
        return '';
      },
      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + 
            String(date.getMonth() + 1).padStart(2, '0')  + '/' + 
            date.getFullYear() + ' ' +
            String(date.getHours()).padStart(2, '0') + ':' + 
            String(date.getMinutes()).padStart(2, '0');
      },
    } 
})
</script>

<style>
  .status-chip .v-chip__content {
    margin: auto;
  }
</style>
