<template>
    <v-select
        v-model="value"
        cache-items
        dense
        outlined
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="SolStaId"
        item-text="SolStaNom"
        no-data-text="Elige un estado"
        :label="label"
        :readonly="readonly"
        return-object
        ref="SolStaFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{ 'open-on-click': true, offsetY: true }"
        @click="clickEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-select>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: ['value', 'minSolSta', 'maxSolSta', 'label', 'multiple', 'readonly', 'smallChips', 'deletableChips'],
    watch: {
      minSolSta() {
        if (this.minSolSta != null) {
          this.loadItems();
        }
      },
      maxSolSta() {
        this.loadItems();
      }
    },
    methods: {
      loadItems() {
        var controllerParameters = {   
          Action: 'GET_SOL_STA_LIST',
          MinSolSta: this.minSolSta,
          MaxSolSta: this.maxSolSta
        };

        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/solsta", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          this.itemsList = [];
          result.data.forEach(element => {
            var itemObject = { SolStaId: element.SolStaId, SolStaNom: element.SolStaNom }
            this.itemsList.push(itemObject);
          });
        });
      },
      clickEvt() {
        this.$emit('click');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
        this.itemsList = [];
        this.loadItems();
    },
  }
</script>